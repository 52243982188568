import {
  Row, Col, Button, Nav,
  Tab,
  Modal
} from "../../bootstrap.js";
import { useState } from "react";
import { Template1 } from "../Templates/Template1.js";
import "./BuildSite.css";
import {BuilderKit} from "./BuilderKit/BuilderKit.js";
import {BuilderKitColor} from "./BuilderKit/BuilderKitColor.js";

export function BuildSite() {

  // const [showPdfModal, setShowPdfModal] = useState(false);

  // const handleClose = async () => await setShowPdfModal(false);
  // const handleShow = async () => await setShowPdfModal(true);
  return (<Row> 
    <Tab.Container defaultActiveKey="Templates">
    <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="navPdfCol">
      <Nav variant="underline">
        <Nav.Item>
          <Nav.Link
            eventKey="Templates"
            className="navUnderlined"
          >
            Templates
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="BuilderKit" className="navUnderlined">
          Builder Kit
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Custom" className="navUnderlined">
            Custom
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Tab.Content>
      <Tab.Pane eventKey="Templates">
      
        <p>Choose a full template. Customize color palette and number of pages.</p>
        <div className="template"> <Template1></Template1></div>
       
      </Tab.Pane>
      <Tab.Pane eventKey="BuilderKit">
     
      <BuilderKit></BuilderKit>
      
      </Tab.Pane>
 
    <Tab.Pane eventKey="Custom">
    
        <p>Customization opportunities coming soon!</p>
        <div className="template"></div>
        </Tab.Pane>
      
      </Tab.Content>
 
  </Tab.Container></Row>)
}