import React, { useState, useEffect, useContext } from 'react';
import { TextField, Typography, Slider, FormControlLabel, Switch } from '@mui/material';
import { SketchPicker } from 'react-color';
import StylingProps from "./StylingProps.js";
import { Row, Col, Button, Image, Card } from "../../bootstrap.js";
import {StylingContext} from "./StylingProvider.js";

const StylingGUI = () => {

  const {
    color,
    colored,
    borderSize,
    borderRadius,
    fontSize,
    fontFamily,
    fontStyle,
    stylingProps,
    handleColorChange,
    handleBorderSizeChange,
    handleBorderRadiusChange,
    // Uncomment if needed
    // handleFontSizeChange,
    // handleFontFamilyChange,
    // handleFontStyleChange,
  } = useContext(StylingContext);
 
 

  return (
    <div style={{ padding: '35px' }}>
      <Typography variant="h5" gutterBottom>
        Customize Styles
      </Typography>
      <div  style={{ marginBottom: '35px' }}>
      <Typography  gutterBottom>
          Color
        </Typography>
        <SketchPicker
          label="Color"
          type="color"
          // value={color}
          color={color}
          onChange={(color)=>{handleColorChange(color)}}
        />
      </div>
      <div style={{ marginBottom: '35px' }}>
        <Typography  gutterBottom>
          Border Radius: {borderRadius}px
        </Typography>
        <Slider
          value={borderRadius}
          min={0}
          max={10}
          step={1}
          onChange={handleBorderRadiusChange}
          aria-labelledby="border-radius-slider"
        />
      </div>
      {/* <div style={{ marginBottom: '35px' }}>
        <Typography id="border-size-slider" gutterBottom>
          Border Size: {borderSize}px
        </Typography>
        <Slider
          value={borderSize}
          min={0}
          max={10}
          step={1}
          onChange={handleBorderSizeChange}
          aria-labelledby="border-size-slider"
        />
      </div> */}
     
      {/* <div style={{ marginBottom: '35px' }}>
        <Typography id="border-radius-slider" gutterBottom>
          Font Size: {fontSize}px
        </Typography>
        <Slider
          value={fontSize}
          min={0}
          max={50}
          step={1}
          onChange={handleFontSizeChange}
          aria-labelledby="font-size-slider"
        />
      </div> */}
      {/* <Button variant="contained" onClick={applyStyles}>
        Apply Stylesa
      </Button> */}
      {/* <StylingProps stylingProps={stylingProps}></StylingProps> */}
    </div>
  );
};

export default StylingGUI;
