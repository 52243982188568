// DroppableContainer.js
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDrop } from 'react-dnd';
import { Container } from 'react-bootstrap';
import DraggableComponentDelete from './DraggableComponentDelete.js';
import ResizableComponent from '../../DragandDropGrid/ResizableComponent.js';
import {NavBar} from '../../NavBar/NavBar.js'; // Import the components you want to add
import {Button} from "../../../bootstrap.js";
import {StylingContext} from "../../StylingGUI/StylingProvider.js";

// import EditableComponent from '../../EditableText/EditableComponent.js'; // Import EditableText component

// Unique ID generator function
const generateId = () => '_' + Math.random().toString(36).substr(2, 9);





const DroppableContainerBuilder = ({ componentList, onDelete }) => {
  const [components, setComponents] = useState([]);

  const { stylingProps } = useContext(StylingContext);



  const componentMap = {
    // NavBar: NavBar,
    Button: (props) => <Button {...props}>Primary Button</Button>,
    // Button: (props) => <Button style={stylingProps}>Primary Button</Button>,
    // Card: (props) => (
    //   <Card style={{ width: '18rem' }} {...props}>
    //     <Card.Body>
    //       <Card.Title>Card Title</Card.Title>
    //       <Card.Text>
    //         Some quick example text to build on the card title and make up the bulk of the card's content.
    //       </Card.Text>
    //     </Card.Body>
    //   </Card>
    // ),
  };
 

  const addComponent = useCallback((componentName) => {
    const ComponentToAdd = componentMap[componentName];
    if (ComponentToAdd) {
      const newComponent = {
        id: components.length,
        left: 0,
        top: 0,
        Component: ComponentToAdd,
      };
      setComponents((prevComponents) => [...prevComponents, newComponent]);
    }
  }, [components.length]);

  const moveComponent = useCallback((id, left, top) => {
    setComponents((prevComponents) =>
      prevComponents.map((comp) =>
        comp.id === id ? { ...comp, left, top } : comp
      )
    );
  }, []);

  const deleteComponent = useCallback((id) => {
    setComponents((prevComponents) => prevComponents.filter((comp) => comp.id !== id));
    onDelete(id); // Update the parent's component list
  }, [onDelete]);

  useEffect(() => {
    if (componentList.length > components.length) {
      const newComponentName = componentList[componentList.length - 1];
      addComponent(newComponentName);
    }
  }, [componentList, components.length, addComponent]);

  const [, drop] = useDrop({
    accept: 'component',
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      moveComponent(item.id, left, top);
      return undefined;
    },
  });

  return (
    <Container ref={drop} style={{ position: 'relative', height: '100vh', border: '2px solid black' }}>
      {components.map((comp) => (
        <DraggableComponentDelete
          key={comp.id}
          id={comp.id}
          left={comp.left}
          top={comp.top}
          onDelete={deleteComponent}
          Component={comp.Component}
        />
      ))}
    </Container>
  );
};

export default DroppableContainerBuilder;