import { Row, Col, Button, Image, Card } from "../../bootstrap.js";
import { ContactButton } from "../ContactButton/ContactButton.js";
import { About } from "../About/About.js";
import "./home.css";



import { NavBar } from "../NavBar/NavBar.js";



export function Home() {

 

  return (
    <>
      <div className="home">
        <Row className="sloganContainer gx-5 gy-2">
          <Col xs={12} sm={12} md={4} className="sloganPlusDot">
            {/* <p className="slogan1">Affordable</p> */}
            <h2 className="slogan1">Dynamic</h2>
            <i className="bi bi-dot "></i>
          </Col>
          <Col xs={12} sm={12} md={4} className="sloganPlusDot">
            <h2 className="slogan1">Customized</h2>
            <i className="bi bi-dot "></i>
          </Col>
          <Col xs={12} sm={12} md={4} className="sloganPlusDot">
            <h2 className="slogan1">Scalable</h2>
            <i className="bi bi-dot "></i>
          </Col>
        </Row>
        <div className="descriptionContainer">
          <h4 className="description">BUILDING YOU DYNAMIC WEB APPLICATIONS</h4>
        </div>
        <div className="sloganContainer2">
          <h3 className="slogan2"> The Altair Way</h3>
        </div>
        {/* <p>Who is this for? Static and dynamic websites, high-functioning apps. Feature-rich, responsive</p> */}

        <div className="nextContact">
          <p>Build your next feature-rich React web app!</p>
          {/* <ContactButton></ContactButton> */}
        </div>
        <About></About>
      </div>
      <div className="startup">Serving both business and social good.</div>
    

    </>
  );
}

/* <p className="slogan2">Build Your Next Web App The Altair Way</p> */

// AltairWeb trail blazes a new market for the every day user, React
// web apps. Built with the same technology used to power Facebook,
// Instagram, and Uber, AltairWeb optimizes meeting client needs by
// providing customizable out-of-the-box web templates to build fast
// web apps at low cost. Our web templates are dynamic and highly
// scalable.

// AltairWeb trail blazes a new market for the every day user, React
//             web apps. Built with the same technology used to power Facebook,
//             Instagram, and Uber, AltairWeb optimizes meeting client needs by
//             building fast web apps at low cost. Our web apps are dynamic and
//             highly scalable.
