import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { NavBar } from "./Components/NavBar/NavBar";
import { Footer } from "./Components/Footer/Footer.js";
import { Routing } from "./Routing.js";
import { WebAcknowledgement } from "./Components/WebAcknowledgement/WebAcknowledgement.js";
// what is asynchronous programming, queuing, caching, logging, security and persistence

function App() {

 


  return (
    <div>
      {/* <NavBar /> */}
      {/* <div className="constructionCont"> */}
      {/* <WebAcknowledgement></WebAcknowledgement>
      <p className="construction">
        This Site Is Under Development. Please check back soon!
      </p> */}
      {/* </div> */}
      <div className="pageLayout">
        <Routing></Routing>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
