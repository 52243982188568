import {useState} from "react";
import BuilderKitList from "./BuilderKitList.js";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DroppableContainerBuilder from './DroppableContainerBuilder.js';
import DroppableContainerBuilder2 from './DroppableContainerBuilder.js';
import 'react-resizable/css/styles.css';
import StylingGUI from "../../StylingGUI/StylingGUI.js";


export function BuilderKit(){
    const [componentList, setComponentList] = useState([]);

    const addComponent = (componentName) => {
      setComponentList((prevList) => [...prevList, componentName]);
    };
  
    const deleteComponent = (id) => {
      setComponentList((prevList) => prevList.filter((_, index) => index !== id));
    };
    return (<div>
        <DndProvider backend={HTML5Backend}>
      <div className="App">
        <BuilderKitList addComponent={addComponent}></BuilderKitList>
     
        <DroppableContainerBuilder2 componentList={componentList} onDelete={deleteComponent} />
      
      </div>
    </DndProvider>
    <StylingGUI></StylingGUI>

    </div>)
}