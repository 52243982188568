export function DEI() {
  return (
    <>
      <p>
        AltairWeb draws from a diverse pool of skill and intentionally removes
        barriers towards coding contributions from traditionally marginalized
        identities such as women, people of color, blind and partially sighted,
        low-income, etc.
      </p>
      <div>
        <h3>
          Guidelines for Diversity, Equity, and Inclusion in Web Development
        </h3>
        <p></p>
      </div>
    </>
  );
}
