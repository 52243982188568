// BuilderKit.js
import React from 'react';
import ResizableComponent from '../../DragandDropGrid/ResizableComponent.js';


const BuilderKitList = ({ addComponent }) => {
  const components = [
    // { name: 'NavBar', component: 'NavBar' },
    { name: 'Button', component: 'Button' },
    // { name: 'Card', component: 'Card' },
  ];

  return (
    <div style={{ border: '1px solid black', padding: '10px', marginBottom: '10px' }}>
      <h4>Builder Kit</h4>
      {components.map((item, index) => (
        <button key={index} onClick={() => addComponent(item.component)} style={{ display: 'block', margin: '5px 0' }}>
          Add {item.name}
        </button>
      ))}
    </div>
  );
};

export default BuilderKitList;



// // BuilderKit.js
// import React from 'react';


// const BuilderKitList = ({ addComponent }) => {
//   const components = [
//     { name: 'NavBar', component: 'NavBar' }, // Add more components as needed
//     { name: 'Button', component: 'Button' },
//     { name: 'Card', component: 'Card' },
//   ];

//   return (
//     <div style={{ border: '1px solid black', padding: '10px', marginBottom: '10px' }}>
//       <h4>Builder Kit</h4>
//       {components.map((item, index) => (
//         <button key={index} onClick={() => addComponent(item.component)} style={{ display: 'block', margin: '5px 0' }}>
//           Add {item.name}
//         </button>
//       ))}
//     </div>
//   );
// };

// export default BuilderKitList;
