import { Header } from "../Header/Header.js";
import { Sonification } from "../../SalvagedCode/Sonfication.js";
import "./socialGood.css";

export function SocialGood() {
  return (
    <div>
      <Header header="Social Good"></Header>
      
      {/* <div>
        <i class=" bi-handshake"></i>
        <h4>Volunteering</h4>
      </div>
      <div>
        <i class=" bi-people-fill"></i>
        <h4>Hackathons</h4>
      </div>
      <div>
        <i class=" bi-people-fill"></i>
        <h4>Equity and Inclusion</h4>
      </div> */}
      <div>
      <h4 className="assistiveTech"> Assistive Tech</h4>
      {/* <h5>Assistive Tech</h5>  */}
     <div className="socialProducts">
        <div>
          <h5>Sonification</h5>
          <p>Sonification gives an audio representation of mathematical functions.  </p>
          <Sonification></Sonification>
        </div>
        <div>
          <h5>Dark Mode</h5>
          <p>Coming Soon!</p>
        </div>
      </div>
      </div>

    </div>
  );
}
