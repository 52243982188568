import React, { useState, createContext, useEffect, useContext } from 'react';

export const StylingContext = createContext();

export function StylingProvider({children}){

    
    const [color, setColor] = useState('#9AC2EA');

    const [colored, setColored] = useState('#09AC2EA');
    const [borderSize, setBorderSize] = useState(1);
    const [borderRadius, setBorderRadius] = useState(0);
  
    //Text
    // const [fontSize, setFontSize] = useState("");
    // const [fontFamily, setFontFamily] = useState("");
    // const [fontStyle, setFontStyle] = useState("");

    let propStyles = {
        backgroundColor: colored,
        border: `${borderSize}px solid ${colored}`,
        borderRadius: `${borderRadius}px`,
        // fontSize: `${fontSize}px`,
      }; 

    const [stylingProps, setStylingProps] = useState(propStyles);
  
    useEffect( () => {
  
      let propStyles = {
        backgroundColor: colored,
        border: `${borderSize}px solid ${colored}`,
        borderRadius: `${borderRadius}px`,
        // fontSize: `${fontSize}px`,
      }; 

      setStylingProps( propStyles);
      console.log(stylingProps);
  
    }, [colored, borderSize, borderRadius, color]);

    //event handlers

    const handleColorChange = async (color) => {
        console.log(color.hex)
        await setColor(color.hex);
        await setColored(color.hex)
        console.log(colored)
    
      };
    
      const handleBorderSizeChange = (event, newValue) => {
        setBorderSize(newValue);
      };
    
      const handleBorderRadiusChange = (event, newValue) => {
        setBorderRadius(newValue);
      };
    
//Text styles 

    //   const handleFontSizeChange = (event, newValue) => {
    //     setFontSize(newValue);
    //   };
    
    //   const handleFontFamilyChange = (event, newValue) => {
    //     setFontFamily(newValue);
    //   };
    
    //   const handleFontStyleChange = (event, newValue) => {
    //     setFontStyle(newValue);
    //   };

    return (<StylingContext.Provider   value={{
        color,
        colored,
        borderSize,
        borderRadius,
        // fontSize,
        // fontFamily,
        // fontStyle,
        stylingProps,
        handleColorChange,
        handleBorderSizeChange,
        handleBorderRadiusChange,
        // handleFontSizeChange,
        // handleFontFamilyChange,
        // handleFontStyleChange,
      }}>

{children}


    </StylingContext.Provider>)
}

