import React, { useState, useContext } from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import {StylingContext} from "../StylingGUI/StylingProvider.js";


const ResizableComponent = ({ Component, onDelete, id, setIsResizing, ...props }) => {
  const [size, setSize] = useState({ width: 200, height: 50 });

  const { stylingProps } = useContext(StylingContext);
  const combinedStyles = {
    width: "100%",
    height: "100%",
    ...stylingProps,
  };


  const onResizeStart = () => {
    setIsResizing(true);
  };

  const onResizeStop = (event, { size }) => {
    setSize(size);
    setIsResizing(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <ResizableBox
        width={size.width}
        height={size.height}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        resizeHandles={["se"]}
        minConstraints={[10, 10]}
        maxConstraints={[600, 400]}
        style={{
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => onDelete(id)}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          &times;
        </button>
        <Component style={combinedStyles} {...props}/>
      </ResizableBox>
    </div>
  );
};

export default ResizableComponent;
