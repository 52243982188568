import React, { useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import ResizableComponent from '../../DragandDropGrid/ResizableComponent.js';



const DraggableComponentDelete = ({ id, left, top, Component, onDelete, ...props }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const componentRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'component',
    item: { id, left, top },
    canDrag: () => !isResizing,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleClick = () => {
    setShowDelete(true);
  };

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowDelete(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={node => {
        drag(node);
        componentRef.current = node;
      }}
      onClick={handleClick}
      style={{
        position: 'absolute',
        left,
        top,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'move',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResizableComponent
        id={id}
        onDelete={onDelete}
        setIsResizing={setIsResizing}
        Component={Component}
        showDelete={showDelete}
        {...props}
      />
    </div>
  );
};

export default DraggableComponentDelete;
