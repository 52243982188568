import { Route, Routes } from "react-router-dom";
import { Home } from "./Components/Home/Home.js";
import { About } from "./Components/About/About.js";
import { Services } from "./Components/Services/Services.js";
import { Contact } from "./Components/Contact/Contact.js";
import { SocialGood } from "./Components/SocialGood/SocialGood.js";
// import { Plans } from "./Components/Plans/Plans.js";
import { DEI } from "./Components/DEI/DEI.js";
import { CreateApp } from "./Components/CreateApp/CreateApp.js";
import {BuildSite} from "./Components/BuildSite/BuildSite.js";
// import { BuilderKit } from "./Components/BuildSite/BuilderKit/BuilderKit.js";
import App from "./App.js";

export function Routing() {
  //use tree datastructure to set routing
  return (
    <Routes>
      {/* <Route path="/" element={<Home></Home>} /> */}
      <Route path="/UnderConstruction1284/Home" element={<Home></Home>} />
      <Route path="/UnderConstruction1284/About" element={<About></About>} />
      <Route path="/UnderConstruction1284/Services" element={<Services></Services>} />
      <Route path="/UnderConstruction1284/Contact" element={<Contact></Contact>} />
      <Route path="/UnderConstruction1284/AltairWebSocial" element={<SocialGood></SocialGood>} />
      <Route path="/UnderConstruction1284/DEI" element={<DEI></DEI>} />
      {/* <Route path="/Plans" element={<Plans></Plans>} /> */}
      <Route path="/UnderConstruction1284/BuildSite" element={<BuildSite></BuildSite>}>
      {/* <Route path="/BuildSite/BuilderKit" element={<BuilderKit></BuilderKit>} /> */}
      </Route>
      
    </Routes>
  );
}
