import "./about.css";
import { Header } from "../Header/Header.js";

import { Card } from "../../bootstrap.js";

export function About() {
  return (
    <Card>
      <div className="about">
        {/* <Header header="What's Under the Hood"></Header> */}
        <Header header="Services"></Header>
        <p>
          Built with
          the same technology used to power Facebook, Instagram, and Uber,
          AltairWeb provides:
        </p>
        <div>
          {/* <i class="bi bi-1-square numberIcon"></i> */}
          <i class="bi bi-server"></i>
          <h4 className="method">Hosting </h4>
          <p>
            Google Cloud Platform (GCP) to host and store data. This ensures
            high security and optimal performance.
          </p>
        </div>
        <div>
          {/* <i class="bi bi-2-square numberIcon"></i> */}
          <i class="bi bi-pencil-square"></i>
          <h4 className="method">Content Mangement System (CMS)</h4>
          <p>
            {/* An admin portal that allows users to directly add, modify, or remove
            web content without developer expertise. #Independence */}
            Templates, a builder kit, and customization opportunities to directly create your own web app.
          </p>
        </div>
        <div>
          {/* <i class="bi bi-3-square numberIcon"></i> */}
          <i class="bi bi-gear-fill"></i>
          <h4 className="method">Feature Integrations</h4>
          <p>
            A diverse set of features such as login,
            notifications, social media feeds, search bars, chatGPT AI integrations, and more.
          </p>
        </div>
        <div>
          {/* <i class="bi bi-3-square numberIcon"></i> */}
          <i class="bi bi-gear-fill"></i>
          <h4 className="method">Export Code</h4>
          <p>
            Take your code with you!
          </p>
        </div>

        <div>
          {/* <i class="bi bi-4-square numberIcon"></i> */}
          <i class="bi bi-universal-access-circle"></i>
          <h4 className="method">Accessibility</h4>
          <p>
            WAI-ARIA compliant and accessibility features such as dark mode and
            sonification to assist blind and partially sighted users.
          </p>
        </div>
      </div>
    </Card>
  );
}

// AltairWeb trail blazes a new market for the every day user, React web
// apps. Built with the same technology used to power Facebook,
// Instagram, and Uber, AltairWeb optimizes meeting client needs by
// providing customizable out-of-the-box web templates to build fast web
// apps at low cost. Our web templates are dynamic and highly scalable. 4
// methods:
