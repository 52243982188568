import { Nav, Navbar, Image } from "../../../bootstrap.js";
// import { ContactButtonNav } from "../ContactButton/ContactButtonNav.js";
import "../../NavBar/navBar.css";
// import { OrgTitle } from "./OrgTitle.js";
import classNames from "classnames";
import { useMediaQuery } from "usehooks-ts";

const landingPage = "Home";

const navTitles = [
  { id: "1", title: `${landingPage}` },
  // { id: "2", title: "About" },
  // { id: "3", title: "Products" }, Graphic Designs
  { id: "4", title: "Plans" },
  // { id: "5", title: "Featured Work" },
  { id: "6", title: "AltairWeb Social" },
  // { id: "7", title: "DEI" },
  { id: "8", title: "Build Site" },
  // { id: "7", title: "Design" },
];

//custom- if absolutely necessary
export function Nav1() {
  const isMobile = useMediaQuery("(max-width: 991px)");
  return (
    <div>
      <div className="navbarPositioning">
        <Navbar.Brand>
          <a href="/" className="brandLink">
            <div className="brandPositioning">
              {isMobile ? (
                <></>
              ) : (
                <>
                  {/* <OrgTitle></OrgTitle> */}
                  <Image
                    src="./images/logo2.png"
                    className="img-fluid logo"
                  ></Image>
                </>
              )}
            </div>
          </a>
        </Navbar.Brand>

        {isMobile ? (
          <>
            <a href="/" className="brandLink">
              <div className="brandPositioning">
                {/* <OrgTitle></OrgTitle> */}
                <Image
                  src="./images/logo2.png"
                  className="img-fluid logo"
                ></Image>
              </div>
            </a>
          </>
        ) : (
          <></>
        )}
        <Navbar expand="lg" className={classNames("bg-body-primary", "nav1")}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {navTitles.map((navTitle) => (
                <>
                  {navTitle.title === landingPage ? (
                    <Nav.Link key={navTitle.id} href="/">
                      {navTitle.title}
                    </Nav.Link>
                  ) : (
                    <Nav.Link key={navTitle.id} href={`/${navTitle.title}`}>
                      {navTitle.title}
                    </Nav.Link>
                  )}
                </>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* {isMobile ? <></> : <ContactButtonNav></ContactButtonNav>} */}
      </div>
      {/* {isMobile ? <ContactButtonNav></ContactButtonNav> : <></>} */}
    </div>
  );
}
